import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USERS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_USER_HOMEWORK } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/userHomeworks/Editor';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminUserHomeworkManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userHomework: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, userHomework } = this.props;

        return (
            <StyledComponent
                className="admin-user-homework-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userHomework}
                >
                    {userHomework && (
                        <PageContent
                            breadcrumbs={[{
                                visible: Boolean(userHomework.data?.user),
                                to: withVariables(ADMIN_USERS_MANAGE.path, { id: userHomework.data?.user && userHomework.data?.user.id }),
                                label: `Użytkownik: ${userHomework.data?.user && getUserFullName(userHomework.data?.user).label}`,
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj pracę domową"
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={userHomework.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_USER_HOMEWORK,
                                                dataId: userHomework.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}